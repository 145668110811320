import request from '@core/http.ts';
import {log} from '@core/utils/dev.js';
import {set} from "@vueuse/core";
import {AxiosResponse} from "axios";
import {isFunction} from "lodash";
import {useMessage} from 'naive-ui';
import {readonly, ref, Ref, unref, UnwrapRef} from 'vue';
import {basePath} from "@admin/routes/router.ts";
import {
  RouteLocation,
  RouteLocationNormalized,
  RouteMeta, Router,
  useRouter
} from "vue-router";

/**
 *
 * @param callbackFn { (function(...args:any[]): Promise<T>) }
 * @param shouldThrow boolean
 * @return {{isLoading: ref<boolean>, execute: (function(...args:any[]): Promise<T|void>)}}
 */
type AsyncCallBackType = (...args: any[]) => any
// tslint:disable-next-line:no-empty
export const useAsyncCallback = (callbackFn: AsyncCallBackType = () => {}, shouldThrow = false) => {
  const isLoading = ref(false);

  const execute = async (...args: any[]) => {
    let requestError: any = null;
    if (isLoading.value) {
      return;
    }

    isLoading.value = true;

    try {
      return await callbackFn(...args);
    } catch (e) {
      log(e);
      requestError = e;
    } finally {
      isLoading.value = false;
    }

    if (shouldThrow && requestError) {
      throw requestError;
    }
  };

  return {
    isLoading,
    execute,
  }
};

export const useAsyncPaginatedData = ({url, getParams, extraKeys = []}: {
  url: any,
  getParams: any,
  extraKeys: any[]
}) => {
  const notification = useMessage();
  const items = ref<any[]>([]);
  const isInitialized = ref(false);
  const pagination = ref({
    page: 1,
    pageSize: 0,
    itemCount: null,
  });

  const extraData = ref({});

  const buildParams = (page: any) => ({
    ...(unref(getParams)?.() ?? {}),
    page: page ?? 1,
  });

  const {isLoading, execute} = useAsyncCallback(async ({page = 1} = {}) => {
    try {
      const _url = typeof unref(url) === 'function' ? unref(url)() : String(unref(url));

      const {data: responseData} = await request.get(_url, {params: buildParams(page)});

      const {data, total, per_page, current_page} = responseData;

      const _extraData: { [key: string | number]: any } = {};
      extraKeys.forEach(key => {
        if (key in responseData) {
          _extraData[key] = responseData[key];
        }
      });
      extraData.value = _extraData;

      if (Array.isArray(data)) {
        items.value = data;
        pagination.value = {
          page: current_page,
          pageSize: per_page,
          itemCount: total,
        };
        isInitialized.value = true;
      }
    } catch (e) {
      notification.error('Error fetching resource!');
    }
  });

  const initialize = () => {
    if (isInitialized.value) {
      return;
    }

    execute?.();
  };

  const onPageChanged = (page: any) => {
    execute?.({page: page ?? 1});
  }

  return {
    isLoading: readonly(isLoading),
    isInitialized: readonly(isInitialized),
    items,
    pagination,
    execute,
    extraData,
    initialize,
    onPageChanged,
  };
};

export const useAsyncData = ({url, getParams}: { url: any; getParams: any }) => {
  const notification = useMessage();
  const response = ref([]);
  const isInitialized = ref(false);

  const buildParams = (params = {}) => {
    return {
      ...(unref(getParams)?.() ?? {}),
      ...(params ?? {}),
    };
  };

  const {isLoading, execute} = useAsyncCallback(async (params = {}) => {
    try {
      const _url = typeof unref(url) === 'function' ? unref(url)() : String(unref(url));

      const {data} = await request.get(_url, {params: buildParams(params)});

      response.value = data;

      isInitialized.value = true;
    } catch (e) {
      notification.error('Error fetching resource!');
    }
  });

  const initialize = () => {
    if (isInitialized.value) {
      return;
    }

    execute?.();
  };

  return {
    isLoading: readonly(isLoading),
    isInitialized: readonly(isInitialized),
    response,
    execute,
    initialize,
  };
};

export type AsyncResponse = {
  data: Ref<any>,
  loading: Ref<UnwrapRef<boolean>>,
  error: Ref<any>,
  execute: (req: Promise<AxiosResponse>, callback?: (((res: any) => void) | undefined)) => Promise<void>
}
export const useAsyncResponse = (promise: Promise<AxiosResponse> | undefined | null = undefined,
                                 callback1: ((res: any) => void) | undefined = undefined, errorMessage = ''): AsyncResponse => {
  const loading = ref<boolean>(false);
  const data = ref<any>();
  const error = ref<any>();
  const execute = async (req: Promise<AxiosResponse>, callback: ((res: any) => void) | undefined = undefined) => {
    const message = window.$message;
    const loadingBar = window.$loading;
    try {
      loadingBar?.start();
      set(loading, true);
      set(error, undefined);
      const {data: _data} = await Promise.resolve(req);
      data.value = _data;
      loadingBar?.finish();
      if (callback) {
        callback(_data);
      }
    } catch (ex: any) {
      set(error, ex);
      loadingBar?.error();
      message?.error(ex?.message || errorMessage);
    } finally {
      set(loading, false);
    }
  };
  if (promise) {
    void execute(promise, callback1);
  }
  return {
    loading,
    data,
    error,
    execute,
  }
};

export const replaceRoute = async (router: Router, prev?: RouteLocation) => {
  if (prev && prev.name) {
    return await router.replace({...prev, replace: true});
  } else {
    return await router.replace({path: basePath, replace: true});
  }
}

export function executeContext(to: RouteLocationNormalized, prev?: RouteLocationNormalized) {
  const {context, request} = to.meta;
  if (!context || !request) {
    return;
  }
  const {execute, error} = context;
  if (!isFunction(execute) || !isFunction(request)) {
    return;
  }
  const router = useRouter();
  (async () => {
    await execute(request(to));
    if (!error.value) {
      return;
    }
    window?.$message?.error(`The Resource [${to.path}] is not accessible`);
    await replaceRoute(router, prev);
  })();
}

export const useRouteMeta = (request: (to: RouteLocationNormalized) => Promise<any>) => {
  return {
    context: useAsyncResponse(),
    request,
  } as RouteMeta
}

export const useRouteProps = (to: RouteLocationNormalized) => {
  const {context, props} = to.meta;
  if (isFunction(props) && context) {
    return props(to, context);
  }
  if (context) {
    return {context};
  }
  return to.params;
}
export default useAsyncCallback;

